<template>
  <div>
    <div class="page-container">
        <div id="govern" class="d-none d-md-block" style="padding: 40px 120px 40px">
      <div class="text-h4 primary-text text-center font-weight-bold mb-15">
        {{ $t("Page.Govern.Title") }}
      </div>
      <v-row class="my-15 px-10">
        <v-col md="3" class="pa-0">
          <div
            class="
              pa-5
              pl-10
              primary-text
              fill-height
              d-flex
              flex-column
              align-left
              justify-start
            "
            style="background: #fff9f7"
          >
            <div class="mb-3 text-h6 font-weight-bold">
              {{ $t("Page.Govern.SubTitle1") }}
            </div>
            <div class="text-body-1">
              <p>● {{ $t("Page.Govern.node1") }}</p>
              <p>● {{ $t("Page.Govern.node2") }}</p>
              <p>● {{ $t("Page.Govern.node3") }}</p>
            </div>
          </div>
        </v-col>
        <v-col md="3" class="pa-0">
          <div class="fill-height split"></div>
        </v-col>
        <v-col md="6" class="pa-0">
          <div
            class="
              pa-5
              primary-text
              fill-height
              d-flex
              flex-column
              align-left
              justify-start
            "
            style="background: #fff9f7"
          >
            <div class="mb-3 text-h6 font-weight-bold">
              {{ $t("Page.Govern.SubTitle2") }}
            </div>
            <div class="text-body-1">
              <p>{{ $t("Page.Govern.SutTitle2Desc") }}</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <div id="govern1" class="d-block d-md-none" style="padding: 40px 20px 20px">
      <div class="text-h5 primary-text text-center font-weight-bold mb-8">
        {{ $t("Page.Govern.Title") }}
      </div>
      <v-row class="my-8 px-6">
        <v-col md="6" class="pa-0">
          <div
            class="
              pa-3
              pl-4
              primary-text
              fill-height
              d-flex
              flex-column
              align-left
              justify-start
            "
            style="background: #fff9f7"
          >
            <div class="mb-1 text-h6 font-weight-bold">
              {{ $t("Page.Govern.SubTitle1") }}
            </div>
            <div class="text-body-1">
              <p>● {{ $t("Page.Govern.node1") }}</p>
              <p>● {{ $t("Page.Govern.node2") }}</p>
              <p>● {{ $t("Page.Govern.node3") }}</p>
            </div>
          </div>
        </v-col>
        <!-- <v-col md="2" class="pa-0">
                        <div class="fill-height split"></div>
                    </v-col> -->
        <v-col md="6" class="pa-0">
          <div
            class="
              pa-3
              primary-text
              fill-height
              d-flex
              flex-column
              align-left
              justify-start
            "
            style="background: #fff9f7"
          >
            <div class="mb-3 text-h6 font-weight-bold">
              {{ $t("Page.Govern.SubTitle2") }}
            </div>
            <div class="text-body-1">
              <p>{{ $t("Page.Govern.SutTitle2Desc") }}</p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    </div>
    

    <Footer></Footer>
  </div>
</template>
  
  <script>
import Footer from "../../components/Footer.vue";

export default {
  name: "Govern111",
  components: {
    Footer,
  },
  mounted() {},
};
</script>
  
  <style lang="scss">
   .page-container {
    min-height: calc(100vh - 289px);
    // padding: 120px;
  }
.fill-height {
  height: 100% !important;
}
.split {
        background: url('../../assets/images/split.png');
        background-size: 100% 100%;
        background-position: center center;
    }
</style>
  